import { useCallback, useState } from "react";
import "../scss/EmptyRow.scss";

export default function EntryRow(entry) {
  const getCorrectTagColor = useCallback(() => {
    var tag = entry.tags;
    var color = "#fff";

    switch (tag) {
      case "essen":
        color = "champagne tag";
        break;
      case "einkaufen":
        color = "grullo tag";
        break;
      case "transport":
        color = "spanish_grey tag";
        break;
      case "airbnb/unterkunft":
        color = "battleship_grey tag";
        break;
      case "ausflüge":
        color = "old_rose tag";
        break;
      case "events":
        color = "dark_sienna tag";
        break;
      case "flüge":
        color = "dark_sienna_l tag";
        break;
      default:
        color = "#fff";
        break;
    }

    return color;
  }, [entry]);
  return (
    <div className="single-entry-row">
      <div className="left">
        <p>{entry.price}€</p>
      </div>
      <div className="right">
        <p>{entry.note.length > 0 && entry.note}</p>
        <p className={getCorrectTagColor()}>{entry.tags}</p>
      </div>
    </div>
  );
}
