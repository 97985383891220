import "../scss/Home.scss";
import arrow from "../assets/expand_more.png";
import { useCallback } from "react";
import React, { useState, useEffect } from "react";
import EntryRow from "../components/EntryRow";
import $ from 'jquery';
import { setCurrentMonthAsDefault } from "../global_function/GlobalFunctions";

export default function Home() {
  //define state
  const [allEntries, setAllEntries] = useState([]);
  const [entriesLina, setEntriesLina] = useState([]);
  const [totalMonthlyLina, setTotalMonthlyLina] = useState(0);
  // const [openLinaOverview, setOpenLinaOverview] = useState(false);
  const [entriesTimo, setEntriesTimo] = useState([]);
  const [totalMonthlyTimo, setTotalMonthlyTimo] = useState(0);
  // const [openTimoOverview, setOpenTimoOverview] = useState(false);
  const [doEntriesExist, setDoEntriesExist] = useState(false);

  //define functions

  const determineTotalPerPerson = useCallback(() => {
    var monthlyTotalLinaL = 0;
    var monthlyTotalTimoL = 0;
    entriesLina.map(function (e, i) {
      monthlyTotalLinaL += parseFloat(e["price"]);
    });
    entriesTimo.map(function (e, i) {
      monthlyTotalTimoL += parseFloat(e["price"]);
    });

    setTotalMonthlyLina(monthlyTotalLinaL);
    setTotalMonthlyTimo(monthlyTotalTimoL);
  }, [entriesLina, entriesTimo]);

  const getAllMonths = useCallback(() => {
    var select = document.getElementById("monthSelect");
    var value = select.value;

    //fetch("https://hhbuchphp.test/getAllEntriesForSpecifiedMonth.php?month=" + value)
    fetch("https://hhbuchphp.linastaudt.de/getAllEntriesForSpecifiedMonth.php?month=" + value)
      .then((response) => response.json())
      .then((data) => {
        setAllEntries(data.ergebnis);
        setEntriesLina(
          data.ergebnis.filter((ergebnis) => ergebnis.payee === "Lina")
        );
        setEntriesTimo(
          data.ergebnis.filter((ergebnis) => ergebnis.payee === "Timo")
        );
        setDoEntriesExist(true);
      });
  }, []);

  const determineDebt = useCallback(() => {
    var median = (totalMonthlyLina + totalMonthlyTimo) / 2;

    if (totalMonthlyLina < median) {
      return "Lina schuldet Timo " + (median - totalMonthlyLina) + "€";
    } else if (totalMonthlyTimo < median) {
      return "Timo schuldet Lina " + (median - totalMonthlyTimo) + "€";
    } else if (totalMonthlyLina === totalMonthlyTimo) {
      return "Die Beträge sind ausgeglichen. Yay";
    } else {
      return "Ein Fehler ist aufgetreten. ";
    }
  }, [totalMonthlyLina, totalMonthlyTimo]);

  const toggleOverviewState = useCallback((e) => {
    if (e === 'timo') {
      $('.payee.timo').toggleClass('active');
    }
    else if (e === 'lina') {
      $('.payee.lina').toggleClass('active');

    }
    else {
      console.log('invalid Request!');
    }
  }, []);

  useEffect(() => {
    setCurrentMonthAsDefault();
    getAllMonths();
  }, [getAllMonths])

  useEffect(() => {
    determineTotalPerPerson();
  }, [
    entriesLina,
    entriesTimo,
    allEntries,
    determineDebt,
    determineTotalPerPerson,
  ]);

  //return html
  return (
    <div className="home">
      <div className="selection">
        <select name="" id="monthSelect" onChange={getAllMonths}>
          <option value="januar">Januar</option>
          <option value="februar">Februar</option>
          <option value="märz">März</option>
          <option value="april">April</option>
          <option value="mai" >Mai</option>
          <option value="juni">Juni</option>
          <option value="juli">Juli</option>
          <option value="august">August</option>
          <option value="september">September</option>
          <option value="oktober">Oktober</option>
          <option value="november">November</option>
          <option value="dezember">Dezember</option>
        </select>
      </div>
      {allEntries.length > 0 ? (
        <div className="results">
          <div className="payee border timo">
            <div className="payee-name">
              <p>Timo</p>
              <img src={arrow} alt="" onClick={(() => toggleOverviewState('timo'))} />
            </div>
            <div className="entries entries_timo">
              {entriesTimo.map((e, i) => (
                <EntryRow
                  key={e["id"]}
                  price={e["price"]}
                  note={e["note"]}
                  tags={e["tags"]}
                />
              ))}
            </div>
            <div class="amount-total">
              <p>Total:</p>
              <p>{totalMonthlyTimo}€</p>
            </div>
          </div>
          <div className="payee border lina">
            <div className="payee-name">
              <p>Lina</p>
              <img src={arrow} alt="" onClick={(() => toggleOverviewState('lina'))} />
            </div>
            <div className="entries entries_lina">
              {entriesLina.map((e, i) => (
                <EntryRow
                  key={e["id"]}
                  price={e["price"]}
                  note={e["note"]}
                  tags={e["tags"]}
                />
              ))}
            </div>
            <div class="amount-total">
              <p>Total:</p>
              <p>{totalMonthlyLina}€</p>
            </div>
          </div>

          <div className="results-evaluation border">
            <h4>Ausgaben Gesamt:</h4>
            <p>Timo: {totalMonthlyTimo}€</p>
            <p>Lina: {totalMonthlyLina}€</p>
            <p>
              = {totalMonthlyLina + totalMonthlyTimo}€ / 2 ={" "}
              {(totalMonthlyLina + totalMonthlyTimo) / 2}€
            </p>
            <p>{determineDebt()}</p>
          </div>
        </div>
      ) : null}
    </div>
  );
}
