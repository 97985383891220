import $ from 'jquery';

export function setCurrentMonthAsDefault(){
    const d = new Date();
    const month = d.getMonth();
    $('#monthSelect option').map(function(i, e) {
      if(i === month){
        e.setAttribute('selected', 'selected')
      }
    })
  }