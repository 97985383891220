import "./scss/App.scss";
import logo from "./assets/hhbuch_logo.png";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Upload from "./pages/Upload";
import Report from "./pages/Report";
import { useCallback } from "react";

function App() {
  const toggleMenu = useCallback(() => {
    const hamburger = document.querySelector(".hamburger");
    const mobileNav = document.querySelector(".mobile-nav");
    hamburger.classList.toggle("is-active");
    mobileNav.classList.toggle("is-active");
  });
  return (
    <div className="App">
      <div className="container">
        <button className="hamburger" onClick={toggleMenu}>
          <div className="bar"></div>
        </button>
        <nav className="mobile-nav">
          <ul>
            <li>
              <a href="/">Zur Übersicht</a>
            </li>
            <li>
              <a href="upload">Upload</a>
            </li>
            <li>
              <a href="report">Report</a>
            </li>
          </ul>
        </nav>
      </div>
      <h1>Haushalts Buch</h1>
      <img src={logo} alt="Logo" />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='upload' element={<Upload />} />
          <Route path='report' element={<Report />} />
        </Routes>
    </div>
  );
}

export default App;
