import { useCallback, useEffect } from "react";
import { useState } from "react";
import "../scss/Upload.scss";
import $ from 'jquery';
import { setCurrentMonthAsDefault } from "../global_function/GlobalFunctions";

export default function Upload() {
  //state?

  //define functions
  const submitForm = useCallback((e) => {
    e.preventDefault();
    //get all values
    var price = document.getElementById("price").value;
    var note = document.getElementById("note").value;
    var tags = document.getElementById("tags").value;
    var payee = document.getElementById("payee").value;
    var month = document.getElementById("monthSelect").value;


    var formData = new FormData();
    formData.append("price", price);
    formData.append("month", month);
    formData.append("note", note);
    formData.append("tags", tags);
    formData.append("payee", payee);

    //fetch("https://hhbuchphp.test/uploadNewEntry.php?price=" + price + "&month=" +month + "&note=" + note + "&tags=" +tags + "&payee=" +payee
    fetch("https://hhbuchphp.linastaudt.de/uploadNewEntry.php?price=" + price + "&month=" + month + "&note=" + note + "&tags=" + tags + "&payee=" + payee
    )
      .then((response) => response.json())
      .then((data) => {
        $('.upload_status').show();

        if (data.status === 'successfull') {
          $('.upload_status').append('<p class="success">Eintrag wurde erfolgreich hochgeladen</p>');
          setTimeout(function () {
            $('p.success').remove();
            $('.upload_status').hide();
          }, 1000)
        }
        else if (data.status === 'failed') {
          $('.upload_status').append('<p class="error">Etwas ist schiefgelaufen. Bitte kontaktiere Lina.</p>');
          setTimeout(function () {
            $('p.error').remove();
            $('.upload_status').hide();
          }, 1000)
        }
        else {
          $('.upload_status').append('<p class="error">Unbekannter Fehler. Bitte kontaktiere Lina.</p>');
          setTimeout(function () {
            $('p.error').remove();
            $('.upload_status').hide();
          }, 1000)
        }
        document.getElementById('upload_form').reset();
      })
      .catch((err) => console.error('error: ' + err));
  }, []);

  useEffect(() => {
    setCurrentMonthAsDefault();
  })

  return (
    <section className="upload">
      <div className="upload_status">
      </div>
      <form method="post" onSubmit={submitForm} id="upload_form">
        <label htmlFor="price">Preis:</label>
        <input type="number" step="0.01" name="price" id="price" />
        <label htmlFor="month_selection">Monat: </label>
        <select name="monthSelect" id="monthSelect">
          <option value="januar">Januar</option>
          <option value="februar">Februar</option>
          <option value="märz">März</option>
          <option value="april">April</option>
          <option value="mai">Mai</option>
          <option value="juni">Juni</option>
          <option value="juli">Juli</option>
          <option value="august">August</option>
          <option value="september">September</option>
          <option value="oktober">Oktober</option>
          <option value="november">November</option>
          <option value="dezember">Dezember</option>
        </select>
        <label htmlFor="note">Notiz(Wer, wie, wo, wann, was?): </label>
        <input type="text" name="note" id="note" />
        <label htmlFor="tags">Tags: </label>
        <select name="tags" id="tags">
          <option value="essen">Essen gehen</option>
          <option value="einkaufen">Einkaufen</option>
          <option value="transport">Transport</option>
          <option value="airbnb/unterkunft">Airbnb/Unterkunft</option>
          <option value="ausflüge">Ausflüge</option>
          <option value="events">Events</option>
          <option value="medizin">Medizin</option>
          <option value="sonstiges">Sonstiges</option>
        </select>
        <label htmlFor="payee">Wer hat gezahlt?: </label>
        <select name="payee" id="payee">
          <option value="Lina">Lina</option>
          <option value="Timo">Timo</option>
        </select>
        <button type="submit">Neu hinzufügen</button>
      </form>
    </section>
  );
}
